<template>
  <div>
    <p class="text-xl mt-6 font-thin">
      Lieu et date de retrait&nbsp;:
    </p>
    <p class="text-xl font-bold uppercase">
      {{ order.shop_title }} {{ order.site_title }}
      <br>
      {{ order.scheduled_at | time('eeee dd MMM') }} entre {{ order.scheduled_at | time("HH'h'mm") }} et {{ upper_scheduled_at | time("HH'h'mm") }}
    </p>
    <div v-if="!order.managed">
      <div class="mt-2" v-show="mode == 'default'">
        <button class="text-teal-600 p-2 text-center cursor-pointer rounded focus:outline-none" @click="mode = 'pickups'">
          <i class="far fa-clock mr-1"></i>
          Modifier la date de retrait
        </button>
      </div>
      <div class="mt-2" v-show="mode == 'success'">
        <p class="text-teal-600 p-2 text-center">
          <i class="fas fa-check mr-1"></i>
          Votre modification a bien été enregistrée.
        </p>
      </div>
      <div class="flex gap-2 mt-4" v-show="mode == 'pickups'">
        <div class="relative inline-flex w-full">
          <svg class="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 232">
            <path d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z" fill="#648299" fill-rule="nonzero"/>
          </svg>
          <select ref="scheduled_at" class="w-full border border-gray-300 rounded-full text-gray-700 h-10 px-5 bg-white hover:border-gray-400 focus:outline-none appearance-none cursor-pointer" v-model="model.scheduled_at">
            <optgroup v-for="pickup in pickups" :label="pickup.day" v-if="pickup.times.length > 0">
              <option v-for="time in pickup.times" :value="time">
                {{ timeOption(time) }}
              </option>
            </optgroup>
          </select>
        </div>
        <button class="border border-gray-300 rounded-full text-gray-700 h-10 px-4 text-sm bg-white hover:border-gray-400" @click="submit()">
          Enregistrer
        </button>
        <button class="border border-gray-300 rounded-full text-gray-700 h-10 px-4 text-sm bg-white hover:border-gray-400" @click="mode = 'default'">
          Annuler
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { parseISO, add, getDay, set, isBefore, isToday, isTomorrow, formatISO, format } from 'date-fns'
  import Time from '../../../filters/Time'
  import axios from 'axios'

  export default {
    filters: {
      Time
    },
    props: {
      order: Object
    },
    data() {
      return {
        refresh: 0,
        mode: 'default',
        model: {
          scheduled_at: this.order.scheduled_at
        },
        busy: false,
        message: null
      }
    },
    computed: {
      upper_scheduled_at() {
        this.refresh
        return add(parseISO(this.order.scheduled_at), { minutes: 15 })
      },
      pickups() {
        const openingHours = [{
          open: false
        }, {
          open: false
        }, {
          open: true,
          slots: [{
            from: {
              hours: 12,
              minutes: 30
            },
            to: {
              hours: 14,
              minutes: 0
            }
          }]
        }, {
          open: true,
          slots: [{
            from: {
              hours: 12,
              minutes: 30
            },
            to: {
              hours: 14,
              minutes: 0
            }
          }]
        }, {
          open: true,
          slots: [{
            from: {
              hours: 12,
              minutes: 30
            },
            to: {
              hours: 14,
              minutes: 0
            }
          }]
        }, {
          open: true,
          slots: [{
            from: {
              hours: 12,
              minutes: 30
            },
            to: {
              hours: 14,
              minutes: 0
            }
          }]
        }, {
          open: true,
          slots: [{
            from: {
              hours: 12,
              minutes: 30
            },
            to: {
              hours: 14,
              minutes: 0
            }
          }]
        }]

        let pickups = []
        let currentDate = new Date()
        let startingDay = getDay(currentDate)
        for (let index = 0; index < 2; index++) {
          let currentDay = (startingDay + index) % 7
          let currentHours = openingHours[currentDay]
          if (currentHours.open) {
            let date = add(currentDate, { days: index })
            currentHours.slots.forEach((slot) => {
              let lower = set(date, { hours: slot.from.hours, minutes: slot.from.minutes, seconds: 0, milliseconds: 0 })
              let upper = set(date, { hours: slot.to.hours, minutes: slot.to.minutes, seconds: 0, milliseconds: 0 })
              let cursor = lower
              let group = {}
              if (isToday(cursor)) {
                group = { day: "Aujourd'hui", times: [] }
              } else if (isTomorrow(cursor)) {
                group = { day: "Demain", times: [] }
              } else {
                group = { day: format(cursor, 'EEEE', { locale: fr }), times: [] }
              }
              let existingGroup = pickups.find(g => g.day === group.day)
              if (existingGroup) {
                group = existingGroup
              } else {
                pickups.push(group)
              }
              while (!isBefore(upper, cursor)) {
                if (isBefore(currentDate, cursor)) {
                  group.times.push(formatISO(cursor))
                }
                cursor = add(cursor, { minutes: 30 })
              }
            })
          }
        }
        return pickups
      }
    },
    methods: {
      timeOption(time) {
        let date = parseISO(time)
        return `Entre ${format(date, "HH'h'mm")} et ${format(add(date, { minutes: 15 }), "HH'h'mm")}`
      },
      submit() {
        if (this.busy) return
        this.busy = true
        axios.patch(
          this.order.url, {
            order: this.model
          }
        ).then((response) => {
          this.busy = false
          this.order.scheduled_at = response.data.scheduled_at
          this.refresh++
          this.message = 'Votre modification a bien été prise en compte.'
          this.mode = 'success'
        }).catch((error) => {
          this.busy = false
          console.error(error)
        })
      }
    }
  }
</script>
