<template>
  <div>
    <div class="md:hidden">
      <div v-for="category in displayedCategories" :key="category.key">
        <div class="relative mt-12 mb-6">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="w-full border-t border-yellow-700"></div>
          </div>
          <div class="relative flex justify-center">
            <span class="px-4 bg-gray-900 uppercase text-xl text-gray-200">
              {{ category.title }}
            </span>
          </div>
        </div>
        <div class="grid gap-6">
          <div
            v-for="product in filteredProducts(category.key)"
            :key="product.id"
            class="rounded overflow-hidden shadow-sm hover:shadow-lg bg-gray-100 relative pb-12"
            >
            <img :src="product.picture_url" :alt="product.title" v-if="product.picture_url" class="w-full">
            <div class="px-6 py-4">
              <div class="flex justify-between items-start text-xl mb-2 font-izakayajoyi font-bold">
                <div class="text-gray-800 leading-tight uppercase">
                  {{ product.title }}
                </div>
                <div class="text-gray-700">
                  {{ product.price | amount }}
                </div>
              </div>
              <div class="text-sm text-yellow-700 -mt-2 mb-2" v-if="product.description">
                {{ product.description }}
              </div>
              <p class="text-gray-600 text-sm uppercase mb-3">
                {{ product.ingredients.join(" • ") }}
              </p>
              <div class="text-xs text-center font-bold text-red-700 p-2 bg-red-200 bg-opacity-25 border border-red-300 rounded-sm mb-3" v-show="product.schedulable_from && product.schedulable_to">
                Disponible seulement entre {{ product.schedulable_from | time("HH'h'") }} et {{ product.schedulable_to | time("HH'h'") }}.
              </div>
              <div class="absolute bottom-0 right-0 pb-6 pr-6 w-full text-right">
                <button
                  class="bg-yellow-700 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded outline-none focus:outline-none"
                  @click="orderProduct(product)">
                  <i class="fas fa-shopping-cart mr-2"></i>
                  Commander
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hidden md:block">
      <div class="flex flex-row justify-between mb-12 text-lg font-thin text-gray-200 text-center uppercase">
        <a
          v-for="category in displayedCategories"
          class="cursor-pointer py-2 tracking-wider"
          :class="{'font-bold border-b-2 border-yellow-700': (currentCategory == category.key)}"
          @click="currentCategory = category.key"
          :key="category.key">
          {{ category.title }}
        </a>
      </div>
      <div class="grid md:grid-cols-2 xl:grid-cols-4 gap-6">
        <div
          v-for="product in filteredProducts(currentCategory)"
          :key="product.id"
          class="rounded overflow-hidden shadow-sm hover:shadow-lg bg-gray-100 relative pb-12"
          >
          <img :src="product.picture_url" :alt="product.title" v-if="product.picture_url" class="w-full">
          <div class="px-6 py-4">
            <div class="flex justify-between items-start text-xl mb-2 font-izakayajoyi font-bold">
              <div class="text-gray-800 leading-none uppercase">
                {{ product.title }}
              </div>
              <div class="text-gray-700 leading-none ml-2">
                {{ product.price | amount }}
              </div>
            </div>
            <div class="text-sm text-yellow-700 -mt-2 mb-2" v-if="product.description">
              {{ product.description }}
            </div>
            <p class="text-gray-600 text-sm uppercase mb-3">
              {{ product.ingredients.join(" • ") }}
            </p>
            <div class="text-xs text-center font-bold text-red-700 p-2 bg-red-200 bg-opacity-25 border border-red-300 rounded-sm mb-3" v-show="product.schedulable_from && product.schedulable_to">
              Disponible seulement entre {{ product.schedulable_from | time("HH'h'") }} et {{ product.schedulable_to | time("HH'h'") }}.
            </div>
            <div class="absolute bottom-0 right-0 pb-6 pr-6 w-full text-right">
              <button
                class="bg-yellow-700 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded outline-none focus:outline-none"
                @click="orderProduct(product)">
                <i class="fas fa-shopping-cart mr-2"></i>
                Commander
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal ref="siteModal">
      <div slot="title">
        <div class="font-bold text-gray-800 uppercase">
          Choisissez votre restaurant
        </div>
      </div>
      <div
        v-for="site in sites"
        :key="site.id"
        class="relative rounded-lg border-2 p-6 my-3 cursor-pointer"
        :class="{'bg-yellow-100 border-yellow-500 shadow-md': isCurrentSite(site)}"
        @click="selectSite(site)"
        >
        <div class="flex items-center">
          <div class="w-6 flex justify-between items-center mr-6">
            <svg class="w-6 h-6" viewBox="0 0 200 200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g :stroke="isCurrentSite(site) ? 'none' : '#111111'" stroke-width="1" fill="none" fill-rule="evenodd">
                <g fill-rule="nonzero">
                  <path d="M31.1442786,171.840796 C5.2779518,146.858262 -5.09578082,109.862896 4.01023318,75.0738981 C13.1162472,40.2848999 40.2848999,13.1162472 75.0738981,4.01023318 C109.862896,-5.09578082 146.858262,5.2779518 171.840796,31.1442786 C209.549474,70.1869539 209.010186,132.247241 170.628714,170.628714 C132.247241,209.010186 70.1869539,209.549474 31.1442786,171.840796 Z" :fill="isCurrentSite(site) ? '#ECC94B' : '#F7FAFC'"></path>
                  <polygon v-if="isCurrentSite(site)" fill="#FFFFF0" points="66.6666667 89.4527363 89.5522388 112.437811 132.338308 69.6517413 146.268657 83.7810945 89.5522388 140.298507 52.7363184 103.482587 66.6666667 89.3532338"></polygon>
                </g>
              </g>
            </svg>
          </div>
          <div class="uppercase leading-none">
            <p class="text-2xl text-gray-800 mb-2">
              Izakaya Joyi
              <span class="font-bold">{{ site.title }}</span>
            </p>
            <p class="text-sm text-gray-700">
              <i class="fas fa-map-marker-alt mr-1 text-yellow-800 text-opacity-50"></i>
              {{ site.street }}
            </p>
          </div>
        </div>
      </div>
      <div slot="footer">
        <div class="flex justify-between items-center">
          <a class="cursor-pointer text-gray-600" @click="$refs.siteModal.close()">
            Annuler
          </a>
          <button class="bg-yellow-600 hover:bg-yellow-500 text-white font-bold py-2 px-4 rounded outline-none focus:outline-none" @click="submitSite()">
            Suivant
            <i class="fas fa-arrow-right ml-2"></i>
          </button>
        </div>
      </div>
    </modal>
    <modal ref="deliveryModal" v-if="currentSite">
      <div slot="title">
        <div class="font-bold text-gray-800 uppercase">
          Mode de livraison
        </div>
      </div>
      <button
        class="w-full rounded-lg border-2 p-6 my-3 text-center bg-gray-200 hover:bg-gray-300 hover:shadow-md cursor-pointer"
        @click="orderProduct(currentProduct, true)">
        <div class="text-2xl font-bold mb-2">
          Click and collect
        </div>
        <div class="text-gray-700">
          Recevez un SMS quand votre commande est
          <br>
          prête à être récupérée au restaurant
        </div>
      </button>
      <a class="block rounded-lg border-2 p-6 my-3 cursor-pointer text-center bg-gray-200 hover:bg-gray-300 hover:shadow-md" :href="currentSite.uber_eats_url" target="_blank">
        <div class="w-32 mx-auto mb-2">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="57 57 369 61" data-reactid="17"><g data-reactid="18"><path fill="#262626" d="M228.06,81.56c-5.47,0-9.35,4.28-9.35,10.85v24.1h-8.35V74.09h8.25v5.18a11.19,11.19,0,0,1,9.94-5.48h3v7.77ZM204.59,95.3c0-12.65-9-22.11-21.18-22.11A21.83,21.83,0,0,0,161.73,95.3c0,12.64,9.75,22.2,22.47,22.2a22.17,22.17,0,0,0,18.3-9.06L196.44,104a14.78,14.78,0,0,1-12.24,6.17,14.22,14.22,0,0,1-14-12.14h34.41Zm-34.21-3.89c1.49-6.47,6.66-10.85,12.93-10.85s11.43,4.38,12.83,10.85Zm-35.1-18.22a21.12,21.12,0,0,0-15,6.27V58.25h-8.35v58.26h8.25v-5.38a21.45,21.45,0,0,0,15.12,6.37,22.16,22.16,0,1,0,0-44.31Zm-.6,36.85A14.69,14.69,0,1,1,149.3,95.4,14.58,14.58,0,0,1,134.68,110Zm-53.5-.4c8.06,0,14.32-6.18,14.32-15.44V58.25h8.65v58.26H95.6V111a21.24,21.24,0,0,1-15.41,6.47c-12.43,0-22-9.06-22-22.8V58.25H67v36C67,103.56,73,109.64,81.18,109.64Z" data-reactid="19"/><path fill="#5fb709" d="M252.32,58.25h40.87v10H263.36V82.45h29v9.66h-29v14.44h29.83v10H252.32ZM406.06,117.6c12.53,0,19.59-6,19.59-14.24,0-5.87-4.18-10.25-12.93-12.15l-9.25-1.89c-5.37-1-7.06-2-7.06-4,0-2.59,2.59-4.18,7.36-4.18,5.17,0,9,1.39,10,6.17h10.84c-.59-9-7.06-14.94-20.18-14.94-11.34,0-19.3,4.68-19.3,13.75,0,6.27,4.38,10.35,13.83,12.34l10.34,2.39c4.08.8,5.17,1.9,5.17,3.59,0,2.69-3.08,4.38-8.06,4.38-6.26,0-9.84-1.39-11.23-6.17H384.28C385.87,111.63,392.53,117.6,406.06,117.6Zm-24.93-1.09H369.4c-7.36,0-11.44-4.58-11.44-10.36V83.25h-8.25V73.49H358V61.24H368.9V73.49h12.23v9.76H368.9v20.11c0,2.29,1.59,3.39,4.08,3.39h8.15Zm-47-43v3.88a21.16,21.16,0,0,0-13.73-5,22.61,22.61,0,1,0,0,45.21,21.1,21.1,0,0,0,13.73-5v3.89H345v-43Zm-12.83,34.65a13.15,13.15,0,1,1,13-13.14A13,13,0,0,1,321.28,108.14Z" data-reactid="20"/></g></svg>
        </div>
        <div class="text-gray-700">
          Faites livrer vos plats à domicile ou au bureau
        </div>
      </a>
      <div slot="footer" v-if="this.sites.length > 1">
        <div class="flex justify-between items-center">
          <a class="cursor-pointer text-gray-600" @click="$refs.deliveryModal.close(); $refs.siteModal.open()">
            <i class="fas fa-arrow-left mr-2"></i>
            Choix du restaurant
          </a>
        </div>
      </div>
    </modal>
    <modal ref="productModal">
      <div slot="title">
        <div class="flex items-baseline divide-x divide-gray-400 text-xl" v-show="currentStep == 0">
          <div class="font-bold text-gray-800 pr-3">
            {{ currentProduct.title }}
          </div>
          <div class="text-gray-700 pl-3">
            {{ currentProduct.price | amount }}
          </div>
        </div>
        <div v-show="currentStep == 1">
          <div class="text-xl font-bold text-gray-800 pr-3">
            Ajouter des extras&nbsp;?
          </div>
          <div class="text-sm text-gray-700 md:mt-3">
            Si vous le souhaitez, vous pouvez ajouter jusqu'à 2 extras de chaque type. Prix indiqués par unité.
          </div>
        </div>
      </div>
      <div v-show="currentStep == 0">
        <div class="text-gray-600 text-sm uppercase" v-if="currentProduct.ingredients">
          {{ currentProduct.ingredients.join(" • ") }}
        </div>
        <div v-if="isAvailable(currentProduct)" class="md:flex md:justify-between items-center my-8">
          <div class="font-bold text-gray-800 leading-none mb-3 md:mb-0">
            Choix des nouilles&nbsp;:
          </div>
          <div class="bg-gray-200 text-sm text-gray-600 leading-none border-2 border-gray-200 rounded-full flex">
            <button
              class="flex-grow focus:outline-none rounded-r-full px-3 py-4"
              :class="{'bg-white rounded-full text-yellow-700 font-bold': (orderItem.variant == 'futsumen')}"
              @click="orderItem.variant = 'futsumen'"
              >
              <span>Futsumen (normales)</span>
            </button>
            <button
              class="flex-grow focus:outline-none rounded-l-full px-3 py-4"
              :class="{'bg-white rounded-full text-yellow-700 font-bold': (orderItem.variant == 'hosomen')}"
              @click="orderItem.variant = 'hosomen'">
              <span>Hosomen (fines)</span>
            </button>
          </div>
        </div>
        <p v-else class="text-red-600 font-bold mt-6 text-center">
          Désolé, ce produit n'est plus disponible dans ce restaurant pour le moment.
        </p>
      </div>
      <div v-show="currentStep == 1">
        <div
          class="my-1 p-2 border rounded"
          v-for="extra in filteredProducts('extra')"
          :class="quantityExtra(extra) > 0 ? 'bg-yellow-100 border-yellow-500' : 'border-0'"
          v-show="orderItem.product_id != 36 || orderItem.product_id === 36 && (extra.id === 19 || extra.id === 16)"
          :key="extra.id">
          <div class="flex justify-between items-center">
            <div class="flex flex-grow flex-col">
              <div class="flex flex-grow items-baseline divide-x divide-gray-200">
                <div class="font-bold text-gray-800 pr-2">
                  {{ extra.title }}
                </div>
                <div class="flex-grow text-gray-700 pl-2">
                  {{ extra.price | amount }}
                </div>
              </div>
              <div class="text-gray-600 text-xs md:text-sm" v-if="extra.ingredients">
                {{ extra.ingredients.join(' • ') }}
              </div>
            </div>
            <div class="w-32 ml-3" v-if="isAvailable(extra)">
              <div class="flex flex-row h-10 w-full rounded-lg bg-transparent">
                <button class="bg-gray-200 text-gray-600 hover:text-gray-700 hover:bg-gray-300 h-full w-20 rounded-l cursor-pointer outline-none focus:outline-none" @click="decrementExtra(extra)">
                  <span class="m-auto text-2xl">−</span>
                </button>
                <input
                  type="text"
                  disabled
                  :value="quantityExtra(extra)"
                  :class="quantityExtra(extra) > 0 ? 'text-gray-900' : 'text-gray-600'"
                  class="outline-none focus:outline-none text-center w-full bg-gray-100 font-semibold text-md hover:text-black focus:text-black md:text-basecursor-default flex items-center">
                <button class="bg-gray-200 text-gray-600 hover:text-gray-700 hover:bg-gray-300 h-full w-20 rounded-r cursor-pointer outline-none focus:outline-none" @click="incrementExtra(extra)">
                  <span class="m-auto text-2xl">+</span>
                </button>
              </div>
            </div>
            <div class="w-32 ml-3 text-red-700 text-sm text-center uppercase opacity-75" v-else>
              Produit épuisé
            </div>
          </div>
        </div>
      </div>
      <div slot="footer">
        <div v-show="currentStep == 0">
          <div v-if="isAvailable(currentProduct)" class="flex justify-between items-center">
            <a class="cursor-pointer text-gray-600" @click="cancelProduct(currentProduct)">
              Annuler
            </a>
            <button class="bg-yellow-600 hover:bg-yellow-500 text-white font-bold py-2 px-4 rounded outline-none focus:outline-none" @click="currentStep++">
              Suivant
              <i class="fas fa-arrow-right ml-2"></i>
            </button>
          </div>
          <div v-else class="flex justify-end items-center">
            <button class="text-gray-600 font-bold py-2 outline-none focus:outline-none" @click="cancelProduct(currentProduct)">
              Fermer
            </button>
          </div>
        </div>
        <div v-show="currentStep == 1">
          <div class="flex justify-between items-center">
            <a class="cursor-pointer text-gray-600" @click="currentStep--">
              <i class="fas fa-arrow-left mr-2"></i>
              Précédent
            </a>
            <button
              class="text-white font-bold py-2 px-4 rounded outline-none focus:outline-none"
              :class="busy ? 'bg-yellow-500 bg-opacity-50 cursor-not-allowed' : 'bg-yellow-600 hover:bg-yellow-500'"
              :disabled="busy"
              @click="submitProduct()">
              <i class="fas mr-1" :class="busy ? 'fa-spinner fa-pulse' : 'fa-check'"></i>
              Valider
            </button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
  import axios from 'axios'
  import Modal from '../../../ui/Modal'
  import Amount from '../../../filters/Amount'
  import Time from '../../../filters/Time'
  import { OrderBus } from '../../../packs/izakayajoyi/front'
  export default {
    components: {
      Modal
    },
    filters: {
      Amount,
      Time
    },
    props: {
      products: Array,
      sites: Array
    },
    data() {
      return {
        order: {},
        busy: false,
        currentSite: null,
        currentCategory: 'bento',
        currentProduct: {},
        currentStep: 0,
        orderItem: { extras: [] },
        displayedCategories: [{
          key: "bento",
          title: "Bento du jour"
        }, {
          key: "sashimi",
          title: "Sashimi"
        }, {
          key: "atsumono",
          title: "Atsumono"
        }, {
          key: "donburi",
          title: "Donburi"
        }, {
          key: "ramen",
          title: "Ramen"
        }, {
          key: "udon",
          title: "Udon"
        }, {
          key: "dessert",
          title: "Desserts"
        }, {
          key: "drink",
          title: "Boissons"
        }, {
          key: "accompagnement",
          title: "Accompagnements"
        }]
      }
    },
    mounted() {
      OrderBus.$on('order:updated', (order) => {
        this.order = order
        this.currentSite = this.sites.find((site) => site.id == this.order.site_id)
      })
    },
    methods: {
      isCurrentSite(site) {
        return this.currentSite && this.currentSite.id === site.id
      },
      isAvailable(product) {
        if (product && product.stocks && this.order.site_id) {
          return product.stocks.find((stock) => stock.site_id == this.order.site_id).available
        } else {
          return true
        }
      },
      submitProduct() {
        if (this.busy) return
        this.busy = true
        axios.post(
          this.order.items_url, {
            order_item: this.orderItem
          }
        ).then((response) => {
          Turbolinks.visit(this.order.url)
        }).catch((error) => {
          console.error(error)
          this.busy = false
        })
      },
      selectSite(site) {
        this.currentSite = site
      },
      submitSite() {
        this.order.site_id = this.currentSite.id
        this.$refs.siteModal.close()
        this.$nextTick(() => this.$refs.deliveryModal.open())
        axios.patch(
          this.order.url, { order: { site_id: this.order.site_id }}
        )
      },
      filteredProducts(category) {
        return this.products.filter((product) => {
          return product.category == category
        })
      },
      orderProduct(product, force = false) {
        this.currentProduct = product
        if (this.sites.length == 1) {
          this.selectSite(this.sites[0])
          this.order.site_id = this.currentSite.id
        }
        if (!force && this.order.items.length == 0) {
          this.submitSite()
        } else {
          this.currentStep = 1
          this.orderItem = {
            product_id: product.id,
            variant: (product.category == 'ramen' ? 'futsumen' : null),
            extras: []
          }
          this.$refs.deliveryModal.close()
          if (product.category == 'ramen' || !this.isAvailable(product)) {
            this.currentStep = 0
            this.$refs.productModal.open()
          } else {
            this.submitProduct()
          }
        }
      },
      cancelProduct(product) {
        this.currentProduct = {}
        this.currentStep = 0
        this.orderItem = { extras: [] }
        this.$refs.productModal.close()
      },
      quantityExtra(extra) {
        const current = this.orderItem.extras.find((item) => item.product_id == extra.id)
        if (current) {
          return current.quantity
        } else {
          return 0
        }
      },
      decrementExtra(extra) {
        const index = this.orderItem.extras.findIndex((item) => item.product_id == extra.id)
        if (index >= 0) {
          const extra = this.orderItem.extras[index]
          if (extra.quantity == 1) {
            this.orderItem.extras.splice(index, 1)
          } else {
            extra.quantity -= 1
          }
        }
      },
      incrementExtra(extra) {
        let current = this.orderItem.extras.find((item) => item.product_id == extra.id)
        if (current) {
          if (current.quantity < 2) {
            current.quantity += 1
          }
        } else {
          current = {
            product_id: extra.id,
            quantity: 1
          }
          this.orderItem.extras.push(current)
        }
      }
    }
  }
</script>
