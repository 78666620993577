<template>
  <a href="/commande" class="py-4 px-6 flex items-center justify-center text-gray-400 hover:text-gray-200">
    <div class="relative mr-2">
      <svg class="fill-current mr-2" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24">
        <path d="M21,7H7.462L5.91,3.586C5.748,3.229,5.392,3,5,3H2v2h2.356L9.09,15.414C9.252,15.771,9.608,16,10,16h8 c0.4,0,0.762-0.238,0.919-0.606l3-7c0.133-0.309,0.101-0.663-0.084-0.944C21.649,7.169,21.336,7,21,7z M17.341,14h-6.697L8.371,9 h11.112L17.341,14z"></path>
        <circle cx="10.5" cy="18.5" r="1.5"></circle>
        <circle cx="17.5" cy="18.5" r="1.5"></circle>
      </svg>
      <span class="absolute top-0 right-0 text-xs w-5 h-5 rounded-full leading-tight flex items-center justify-center shadow" :class="empty ? 'bg-gray-600 text-gray-900' : 'bg-gray-900 text-yellow-400'">
        {{ order.quantity }}
      </span>
    </div>
    <span class="font-bold font-izakayajoyi" :class="empty ? 'text-gray-600' : 'text-gray-200'">
      {{ order.total_amount | amount }}
    </span>
  </a>
</template>

<script>
  import { OrderBus } from '../../../packs/izakayajoyi/front'
  import Amount from '../../../filters/Amount'
  export default {
    filters: {
      Amount
    },
    data() {
      return {
        order: {}
      }
    },
    mounted() {
      OrderBus.$on('order:updated', (order) => {
        this.order = order
      })
    },
    computed: {
      empty() {
        return this.order.quantity == 0
      }
    }
  }
</script>
