<template>
  <div class="max-w-xl px-6 mx-auto" ref="container">
    <div v-show="step == 'summary'">
      <h1 class="mb-6 md:mb-12 text-xl md:text-3xl uppercase leading-tight text-center text-gray-200 font-izakayajoyi font-bold tracking-wide">
        Récapitulatif de votre commande
      </h1>
      <div class="text-center" v-if="empty">
        <p class="text-gray-600 mb-12">
          Votre panier est vide.
        </p>
        <a href="/" class="bg-yellow-600 hover:bg-yellow-500 text-white font-bold p-4 rounded outline-none focus:outline-none">
          Retour à l'accueil
        </a>
      </div>
      <div v-else>
        <div class="relative rounded border border-gray-400 bg-white p-4 md:p-8 my-3 shadow-md" v-for="item in order.items" :key="item.id">
          <div class="divide-y divide-gray-300">
            <div class="flex mb-2">
              <div class="flex-grow">
                <span class="font-bold text-2xl text-gray-900 uppercase font-izakayajoyi leading-none">
                  {{ item.product.title }}
                </span>
                <div class="text-xs text-gray-600 mt-1 uppercase">
                  {{ item.product.ingredients.join(' • ') }}
                </div>
              </div>
              <div class="text-gray-700 pl-6 text-2xl font-izakayajoyi leading-none">
                {{ item.amount | amount }}
              </div>
            </div>
            <div class="flex text-sm py-2" v-show="item.variant">
              <div class="flex-grow">
                <span class="text-gray-600">
                  Choix des nouilles
                </span>
              </div>
              <div class="text-gray-700 pl-6" v-show="item.variant == 'futsumen'">
                Futsumen (normales)
              </div>
              <div class="text-gray-700 pl-6" v-show="item.variant == 'hosomen'">
                Hosomen (fines)
              </div>
            </div>
            <div class="flex text-sm py-2" v-for="extra in item.extras" :key="extra.id">
              <div class="flex-grow">
                <span class="text-gray-800">
                  {{ extra.product.title }}
                </span>
                <span class="text-gray-600 ml-1">
                  &times;
                  {{ extra.quantity }}
                </span>
                <div class="text-xs text-gray-500 uppercase">
                  {{ extra.product.ingredients.join(' • ') }}
                </div>
              </div>
              <div class="text-gray-700 pl-6">
                {{ extra.amount | amount }}
              </div>
            </div>
            <div class="flex pt-3">
              <div class="flex-grow text-gray-600">
                Sous-total
              </div>
              <div class="font-bold font-izakayajoyi">
                {{ item.total_amount | amount }}
              </div>
            </div>
          </div>
          <div class="mt-3">
            <a class="border border-red-100 block w-full text-center bg-red-100 bg-opacity-75 hover:bg-opacity-100 text-opacity-75 hover:text-opacity-100 text-red-700 py-2 text-sm cursor-pointer rounded" v-show="!item.removable" @click="$set(item, 'removable', true)">
              Retirer du panier
            </a>
            <div class="grid md:grid-cols-2 gap-3 grid-flow-row-dense" v-show="item.removable">
              <a class="border border-red-700 bg-red-600 text-center text-white py-2 text-sm cursor-pointer rounded" @click="removeItem(item)">
                Confirmer le retrait du panier ?
              </a>
              <a class="bg-gray-200 text-gray-700 py-2 text-center text-sm cursor-pointer rounded" @click="item.removable = false">
                Annuler
              </a>
            </div>
          </div>
        </div>
        <div class="rounded border border-gray-400 bg-white p-4 divide-y divide-gray-300 shadow-md my-3">
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <div class="mr-2">
                <svg width="48px" height="48px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                  <path fill="#444" d="M253.8 19.46h-1.1c-1.8.1-3.5.34-5.1.85-4.2 1.3-7.4 3.84-9.6 9.49l-14.5 39.6 33.8 12.74 16.4-38.93c2.2-5.65 1.6-9.64-.6-13.3-2.2-3.8-6.3-7.04-11.2-8.87-2.6-1-5.4-1.53-8.1-1.59zM217.4 86.2 82.21 455.4l15.84 5.9L250.4 98.63zm189.8 11.6c-4.3.2-8.1 1.85-11.8 6.7l-24.5 34.6 29.3 20.8 26.1-33.4c3.7-4.8 4.1-8.9 3-12.9-1.2-4.3-4.2-8.4-8.5-11.4-3.7-2.67-8-4.21-11.9-4.4h-1.7zm-46.7 55.9L132.1 476l13.7 9.7 243.3-311.6z"/>
                </svg>
              </div>
              <div class="flex-grow text-gray-600">
                Souhaitez-vous des baguettes&nbsp;?
              </div>
            </div>
            <div class="bg-gray-200 text-sm text-gray-600 leading-none border-2 border-gray-200 rounded-full flex">
              <button
                class="flex-grow focus:outline-none rounded-r-full px-6 py-4"
                :class="{'bg-white rounded-full text-yellow-700 font-bold': model.chopsticks}"
                @click="model.chopsticks = true">
                <span>Oui</span>
              </button>
              <button
                class="flex-grow focus:outline-none rounded-l-full px-6 py-4"
                :class="{'bg-white rounded-full text-yellow-700 font-bold': !model.chopsticks}"
                @click="model.chopsticks = false">
                <span>Non</span>
              </button>
            </div>
          </div>
        </div>
        <div class="rounded border border-gray-400 bg-white p-4 md:p-8 divide-y divide-gray-300 shadow-md">
          <div class="flex pb-3">
            <div class="flex-grow text-gray-600">
              Total TTC
            </div>
            <div class="text-right font-bold font-izakayajoyi">
              {{ order.total_amount | amount }}
            </div>
          </div>
          <div class="flex py-2 text-sm" v-for="(amount, vat) in order.vat_parts">
            <div class="flex-grow text-gray-500">
              TVA {{ vat | percentage }}
            </div>
            <div class="text-right text-gray-600 font-izakayajoyi">
              {{ amount | amount }}
            </div>
          </div>
          <div class="flex pt-3 text-sm md:text-base">
            <div class="flex-grow text-gray-600">
              Restaurant sélectionné
            </div>
            <div class="text-right uppercase font-bold font-izakayajoyi">
              Izakaya Joyi {{ currentSite.title }}
            </div>
          </div>
        </div>
        <div class="mt-6 flex flex-col md:flex-row-reverse items-center justify-between">
          <div class="mb-6 md:mb-0 w-full md:w-auto">
            <a @click="step = 'pickup'" class="cursor-pointer block w-full text-center bg-yellow-600 hover:bg-yellow-500 text-white font-bold p-4 rounded outline-none focus:outline-none">
              <i class="fas fa-check mr-1"></i>
              Valider la commande
            </a>
          </div>
          <div class="mt-6 mb-12 md:my-0">
            <a class="cursor-pointer text-gray-200" href="/click-and-collect">
              <i class="fas fa-arrow-left mr-2"></i>
              Ajouter d'autres produits
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-show="step == 'pickup'">
      <h1 class="mb-6 md:mb-12 text-xl md:text-3xl uppercase leading-tight text-center text-gray-200 font-izakayajoyi font-bold tracking-wide">
        Retrait de votre commande
      </h1>
      <div class="rounded border border-gray-400 bg-white p-4 lg:p-6 my-3">
        <p class="text-gray-700 text-lg flex items-center font-izakayajoyi">
          <i class="far fa-clock fa-3x mr-3 text-gray-500"></i>
          À quelle heure souhaitez-vous récupérer votre commande au restaurant&nbsp;?
        </p>
        <div class="flex items-start mt-4 text-sm text-blue-700 font-bold" v-if="!displayEveningSlots()">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2">
            <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
          </svg>
          <span>Votre commande contient des produits disponibles au retrait uniquement entre 12h30 et 14h.</span>
        </div>
        <div class="relative inline-flex w-full mt-6">
          <svg class="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 232">
            <path d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z" fill="#648299" fill-rule="nonzero"/>
          </svg>
          <select ref="scheduled_at" class="w-full border border-gray-300 rounded-full text-gray-700 h-10 px-5 bg-white hover:border-gray-400 focus:outline-none appearance-none cursor-pointer" v-model="model.scheduled_at">
            <optgroup v-for="pickup in pickups" :label="pickup.day" v-if="pickup.times.length > 0">
              <option v-for="time in pickup.times" :value="time">
                {{ timeOption(time) }}
              </option>
            </optgroup>
          </select>
        </div>
        <div v-if="hasUnschedulableProduct" class="text-red-700 text-center bg-red-200 bg-opacity-50 border border-red-500 px-4 py-2 rounded mt-4">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
          </div>
          <div>
            Vous avez commandé des produits disponibles seulement de <b>{{ schedulableTimes[0][0] }}</b> à <b>{{ schedulableTimes[0][1] }}</b>. Merci de sélectionner un autre horaire de retrait ou de modifier votre commande.
          </div>
        </div>
        <div class="border relative rounded p-1 mt-8">
          <div class="-mt-4 absolute tracking-wider px-1">
            <p>
              <label for="first_name" class="bg-white text-gray-600 px-2 font-izakayajoyi">
                Votre nom
                <span class="text-red-700">*</span>
              </label>
            </p>
          </div>
          <p>
            <input id="first_name" autocomplete="false" tabindex="0" type="text" class="p-4 text-gray-900 outline-none block h-full w-full" v-model.trim="model.first_name">
          </p>
        </div>
      </div>
      <div class="rounded border border-gray-400 bg-white p-4 lg:p-6 my-3">
        <p class="text-gray-700 text-lg flex items-center font-izakayajoyi">
          <i class="fas fa-sms fa-3x mr-3 text-gray-500"></i>
          Recevoir un SMS lorsque votre commande est prête&nbsp;?
        </p>
        <div class="border relative rounded p-1 mt-6">
          <div class="-mt-4 absolute tracking-wider px-1 font-izakayajoyi">
            <p>
              <label for="phone" class="bg-white text-gray-600 px-2">
                Votre numéro de téléphone mobile
              </label>
            </p>
          </div>
          <p>
            <input id="phone" tabindex="0" type="tel" class="p-4 text-gray-900 outline-none block h-full w-full" v-model.trim="model.phone" v-mask="'0# ## ## ## ##'">
          </p>
        </div>
      </div>
      <div class="mt-6 flex flex-col md:flex-row-reverse items-center justify-between">
        <button
          @click="submitOrder()"
          :disabled="busy || !valid"
          :class="(busy || !valid) ? 'bg-yellow-500 bg-opacity-50 cursor-not-allowed' : 'bg-yellow-600 hover:bg-yellow-500'"
          class="mb-6 md:mb-0 w-full md:w-auto cursor-pointer text-center text-white font-bold px-6 py-4 rounded outline-none focus:outline-none">
          <i class="fas fa-fw mr-2" :class="busy ? 'fa-spinner fa-pulse' : 'fa-credit-card'"></i>
          Payer la commande
        </button>
        <div class="text-center md:text-left mb-6 md:mb-0">
          <div class="font-light text-sm mb-1 text-gray-500">
            <i class="fa fa-lock mr-1"></i>
            <span>Paiement en ligne sécurisé</span>
          </div>
          <div>
            <i class="text-blue-700 fab fa-cc-visa fa-2x mr-2"></i>
            <i class="text-gray-500 fab fa-cc-mastercard fa-2x mx-2"></i>
            <i class="text-teal-700 fab fa-cc-amex fa-2x ml-2"></i>
          </div>
        </div>
      </div>
      <form :action="transaction.url" method="post" class="hidden" accept-charset="UTF-8" ref="paymentForm" v-if="transaction">
        <input type="hidden" :name="name" :value="value" v-for="(value, name) in transaction.params">
      </form>
    </div>
  </div>
</template>

<script>
  import { format, add, set, isBefore, isAfter, isToday, isTomorrow, parseISO, formatISO, getDay, getHours, getMinutes } from 'date-fns'
  import axios from 'axios'
  import { mask } from 'vue-the-mask'
  import Amount from '../../../filters/Amount'
  import Time from '../../../filters/Time'
  import Percentage from '../../../filters/Percentage'
  import { OrderBus } from '../../../packs/izakayajoyi/front'
  export default {
    directives: {
      mask
    },
    filters: {
      Amount,
      Time,
      Percentage
    },
    props: {
      sites: Array
    },
    data() {
      return {
        step: 'summary',
        order: {
          items: []
        },
        transaction: null,
        model: {
          first_name: null,
          scheduled_at: null
        },
        busy: false
      }
    },
    mounted() {
      OrderBus.$on('order:updated', (order) => {
        this.order = order
        this.model = {
          first_name: order.first_name,
          message: order.message,
          phone: order.phone,
          scheduled_at: this.pickups.flatMap((pickup) => pickup.times)[0],
          chopsticks: order.chopsticks
        }
      })
    },
    computed: {
      hasUnschedulableProduct() {
        if (!this.model.scheduled_at) return

        const scheduledAt = parseISO(this.model.scheduled_at)
        const scheduledHours = getHours(scheduledAt)
        const scheduledMinutes = getMinutes(scheduledAt)
        let result = this.schedulableTimes.some((slot) => {
          let slotFrom = slot[0].split(':')
          let slotTo = slot[1].split(':')
          return (
            (scheduledHours < parseInt(slotFrom[0]) || scheduledHours == parseInt(slotFrom[0]) && scheduledMinutes < parseInt(slotFrom[1])) ||
            (scheduledHours > parseInt(slotTo[0]) || scheduledHours == parseInt(slotTo[0]) && scheduledMinutes > parseInt(slotTo[1]))
          )
        })
        return result
      },
      schedulableTimes() {
        return this.order.items.filter((item) => {
          return item.product.schedulable_from != null
        }).map((item) => {
          return [
            format(parseISO(item.product.schedulable_from), 'HH:mm'),
            format(parseISO(item.product.schedulable_to), 'HH:mm')
          ]
        })
      },
      valid() {
        return (
          !this.hasUnschedulableProduct &&
          this.model.first_name != null &&
          this.model.first_name != '' &&
          this.model.scheduled_at != null &&
          (
            this.model.phone == null ||
            this.model.phone == '' ||
            this.model.phone.replace(/\s/g, '').match(/0[6,7]\d{8}/) != null
          )
        )
      },
      empty() {
        return this.order.items.length == 0
      },
      pickups() {
        const openingHours = [{
          // Dimanche
          open: false
        }, {
          // Lundi
          open: false
        }, {
          // Mardi
          open: true,
          slots: [{
            from: {
              hours: 12,
              minutes: 30
            },
            to: {
              hours: 14,
              minutes: 0
            }
          }]
        }, {
          // Mercredi
          open: true,
          slots: [{
            from: {
              hours: 12,
              minutes: 30
            },
            to: {
              hours: 14,
              minutes: 0
            }
          }]
        }, {
          // Jeudi
          open: true,
          slots: [{
            from: {
              hours: 12,
              minutes: 30
            },
            to: {
              hours: 14,
              minutes: 0
            }
          }, {
            from: {
              hours: 19,
              minutes: 30
            },
            to: {
              hours: 22,
              minutes: 0
            }
          }]
        }, {
          // Vendredi
          open: true,
          slots: [{
            from: {
              hours: 12,
              minutes: 30
            },
            to: {
              hours: 14,
              minutes: 0
            }
          }, {
            from: {
              hours: 19,
              minutes: 30
            },
            to: {
              hours: 22,
              minutes: 0
            }
          }]
        }, {
          // Samedi
          open: true,
          slots: [{
            from: {
              hours: 12,
              minutes: 30
            },
            to: {
              hours: 14,
              minutes: 0
            }
          }, {
            from: {
              hours: 19,
              minutes: 30
            },
            to: {
              hours: 22,
              minutes: 0
            }
          }]
        }]
        let pickups = []
        let currentDate = new Date()
        let startingDay = getDay(currentDate)
        for (let index = 0; index < 2; index++) {
          let currentDay = (startingDay + index) % 7
          let currentHours = openingHours[currentDay]
          if (currentHours.open) {
            let date = add(currentDate, { days: index })
            currentHours.slots.forEach((slot) => {
              let lower = set(date, { hours: slot.from.hours, minutes: slot.from.minutes, seconds: 0, milliseconds: 0 })
              let upper = set(date, { hours: slot.to.hours, minutes: slot.to.minutes, seconds: 0, milliseconds: 0 })
              let cursor = lower
              let group = {}
              if (isToday(cursor)) {
                group = { day: "Aujourd'hui", times: [] }
              } else if (isTomorrow(cursor)) {
                group = { day: "Demain", times: [] }
              } else {
                group = { day: format(cursor, 'EEEE', { locale: fr }), times: [] }
              }
              let existingGroup = pickups.find(g => g.day === group.day)
              if (existingGroup) {
                group = existingGroup
              } else {
                pickups.push(group)
              }
              while (isAfter(upper, cursor)) {
                let hours = getHours(cursor)
                if (isBefore(currentDate, cursor) && (hours <= 14 || hours > 14 && this.displayEveningSlots())) {
                  group.times.push(formatISO(cursor))
                }
                cursor = add(cursor, { minutes: 15 })
              }
            })
          }
        }
        return pickups
      },
      currentSite() {
        return this.sites.find((site) => site.id == this.order.site_id)
      }
    },
    methods: {
      timeOption(time) {
        let date = parseISO(time)
        return `Entre ${format(date, "HH'h'mm")} et ${format(add(date, { minutes: 15 }), "HH'h'mm")}`
      },
      removeItem(item) {
        axios.delete(item.url).then((response) => {
          OrderBus.$emit('order:updated', response.data)
        })
      },
      submitOrder() {
        if (this.busy) return
        this.busy = true
        axios.patch(
          this.order.url, {
            order: this.model
          }
        ).then((response) => {
          axios.post(
            this.order.transaction_url
          ).then((response) => {
            this.transaction = response.data
            this.$nextTick(() => {
              this.$refs.paymentForm.submit()
            })
          })
        }).catch((error) => {
          this.busy = false
          console.error(error)
        })
      },
      displayEveningSlots() {
        let productIds = this.order.items.map((item) => item.product.id)
        return !(productIds.includes(74) || productIds.includes(75)) // Bento
      }
    },
    watch: {
      step() {
        window.scrollTo(0, 0)
      },
      'model.scheduled_at': function(value) {
        let text = ''
        if (value == null) {
          text = "Dès que possible"
        } else {
          let from = parseISO(value)
          let to = add(from, { minutes: 15 })
          if (isToday(from)) {
            text = `Aujourd'hui entre ${format(from, "HH'h'mm")} et ${format(to, "HH'h'mm")}`
          } else {
            text = `Demain entre ${format(from, "HH'h'mm")} et ${format(to, "HH'h'mm")}`
          }
        }
        this.$nextTick(() => {
          this.$refs.scheduled_at.selectedOptions[0].text = text
        })
      }
    }
  }
</script>
